import React from "react";
import MyCarousel from "../Components/MyCarousel";

export default function ProjectsPage() {
  return (
    <div>
      <MyCarousel />
    </div>
  );
}
