import React from "react";
import MyForm from "../Components/MyForm";

export default function ContactPage() {
  return (
    <div>
      <MyForm />
    </div>
  );
}
