import React from "react";
import CardAboutMe from "../Components/CardAboutMe";

export default function AboutPage() {
  return (
    <div>
      <CardAboutMe />
    </div>
  );
}
